import Constants from '../../config.local.js'
import Horse from '@/mixins/Horse'
import HorseTransformer from '@/assets/js/dexie/transformers/HorseTransformer.js'
import PlanningTransformer from '@/assets/js/dexie/transformers/PlanningTransformer.js'
import PlanningScheduleTransformer from '@/assets/js/dexie/transformers/PlanningScheduleTransformer.js'
import PlanningHorseTransformer from '@/assets/js/dexie/transformers/PlanningHorseTransformer.js'
import PlanningScheduleHorseTransformer from '@/assets/js/dexie/transformers/PlanningScheduleHorseTransformer.js'
import SeasonTransformer from '@/assets/js/dexie/transformers/SeasonTransformer.js'
import Common from '@/assets/js/common'
import _orderBy from 'lodash/orderBy'

var PlanningMonte = {
	mixins: [Horse],

    methods: {

    	async getCurrentSeason(date) {
			if(!this.$storage.db.season) {
				return []
			}

			date.setHours(0,0,0,0);

    		return await this.$storage.db.t('season')
            .then(table => {
                return table.toCollection()
                .filter(season => (season.season_start <= date && season.season_end >= date))
            })
            .then(col => {
                return col.transform(new SeasonTransformer())
            })
    	},

    	async saveParametrePlanningMain(season_id, lieu_id, schedule, stallion, def = false){
            // return this.$storage.db.transaction(
            //     'rw',
            //     ['planning', 'planning_schedule', 'planning_horse'],
            //     async () => {
                	const planning = await this.$storage.db.t('planning')
			            .then(table => {
			                return table.where({
			                	planning_season: parseInt(season_id), 
			                	planning_type: "main", 
			                	planning_lieu: parseInt(lieu_id)
			                }).first()
						})
						.then(plan => {
							return PlanningTransformer.process(plan, 'light')
						})

    				const planning_id = planning ? planning.planning_id : Common.getNegativeId()

    				// si le planning est coché par défaut alors j'enlève le "par défaut" aux autres planning de la saison
    				if(def) {
    					await this.$storage.db.t('planning')
			            .then(table => {
			                return table.where({
			                	planning_season: parseInt(season_id), 
			                	planning_type: "main", 
			                }).modify({planning_default: false})
						})
    				}

    				if(!planning) {
	    				await this.$storage.db.t('planning')
			            .then(table => {
			                return table.add({
			                    planning_id: parseInt(planning_id),
			                    planning_season: parseInt(season_id),
			                	planning_lieu: parseInt(lieu_id),
			                    planning_type: "main",
			                    planning_default: def,
			                    planning_valide: 1
			                })
			            })
			        } else {
			        	await this.$storage.db.t('planning')
					    .then(table => {
					    	return table.where('planning_id').equals(parseInt(planning_id)).modify({planning_default: def})
					    })
			        }

			        const schedule_ids = schedule.filter(sch => sch.id).map(sch => sch.id)

		            await this.$storage.db.t('planning_schedule')
					    .then(table => {
					    	return table.where('schedule_id').noneOf(schedule_ids)
					    				.and(sch => sch.schedule_planning == parseInt(planning_id))
					    				.invalid()
					    })

		            schedule.forEach(async sch => {
		            	if(!sch.id) {
			            	await this.$storage.db.t('planning_schedule')
				            .then(table => {
				                return table.add({ 
		                            schedule_id: Common.getNegativeId(),
		                            schedule_planning: parseInt(planning_id),
		                            schedule_start: sch.start,
		                            schedule_end: sch.end,
		                            schedule_order: sch.order,
		                            schedule_valide: 1,
		                        })
				            })
				        } else {
				        	await this.$storage.db.t('planning_schedule')
				            .then(table => {
				                return table.where({schedule_id: sch.id})
				                			.modify({
					                            schedule_start: sch.start,
					                            schedule_end: sch.end,
		                            			schedule_order: sch.order,
				                			})
							})
				        }
		            })

		            await this.$storage.db.t('planning_horse')
					    .then(table => {
					    	return table.where('planninghorse_planning').equals(parseInt(planning_id)).invalid()
					    })

		            stallion.forEach(async horse => {
		            	await this.$storage.db.t('planning_horse')
			            .then(table => {
			                return table.add({ 
	                            planninghorse_id: Common.getNegativeId(),
	                            planninghorse_planning: parseInt(planning_id),
	                            planninghorse_stallion: horse.horse_id,
	                            planninghorse_order: parseInt(horse.order),
	                            planninghorse_valide: 1,
	                        })
			            })
		            })

                	return planning_id
            //     }
            // )
    	},

    	async saveParametrePlanningIA(season_id, lieu_id, schedule, def = false){
            // return this.$storage.db.transaction(
            //     'rw',
            //     ['planning', 'planning_schedule', 'planning_horse'],
            //     async () => {
                	const planning = await this.$storage.db.t('planning')
			            .then(table => {
			                return table.where({
			                	planning_season: parseInt(season_id), 
			                	planning_type: "IA",
			                	planning_lieu: parseInt(lieu_id)
			                }).first()
						})
						.then(plan => {
							return PlanningTransformer.process(plan, 'light')
						})

    				const planning_id = planning ? planning.planning_id : Common.getNegativeId()

    				// si le planning est coché par défaut alors j'enlève le "par défaut" aux autres planning de la saison
    				if(def) {
    					await this.$storage.db.t('planning')
			            .then(table => {
			                return table.where({
			                	planning_season: parseInt(season_id), 
			                	planning_type: "IA", 
			                }).modify({planning_default: false})
						})
    				}

    				if(!planning) {
	    				await this.$storage.db.t('planning')
			            .then(table => {
			                return table.add({
			                    planning_id: parseInt(planning_id),
			                    planning_season: parseInt(season_id),
			                	planning_lieu: parseInt(lieu_id),
			                    planning_type: "IA",
			                    planning_default: def,
			                    planning_valide: 1
			                })
			            })
			        } else {
			        	await this.$storage.db.t('planning')
					    .then(table => {
					    	return table.where('planning_id').equals(parseInt(planning_id)).modify({planning_default: def})
					    })
			        }

			        const schedule_ids = schedule.filter(sch => sch.id).map(sch => sch.id)

		            await this.$storage.db.t('planning_schedule')
					    .then(table => {
					    	return table.where('schedule_id').noneOf(schedule_ids)
			    				.and(sch => sch.schedule_planning == parseInt(planning_id))
			    				.invalid()
					    })

		            await this.$storage.db.t('planning_horse')
					    .then(table => {
					    	return table.where('planninghorse_schedule').noneOf(schedule_ids)
			    				.and(sch => sch.planninghorse_planning == parseInt(planning_id))
								.invalid()
					    })


		            schedule.forEach(async sch => {
		            	const schedule_id = Common.getNegativeId()

			            if(!sch.id) {
			            	await this.$storage.db.t('planning_schedule')
				            .then(table => {
				                return table.add({ 
		                            schedule_id: schedule_id,
		                            schedule_planning: parseInt(planning_id),
		                            schedule_start: sch.start,
		                            schedule_end: sch.end,
		                            schedule_order: sch.order,
		                            schedule_valide: 1,
		                        })
				            })

				            await this.$storage.db.t('planning_horse')
				            .then(table => {
				                return table.add({ 
		                            planninghorse_id: Common.getNegativeId(),
		                            planninghorse_planning: parseInt(planning_id),
		                            planninghorse_stallion: sch.stallion.planninghorse_stallion.horse_id,
		                            planninghorse_schedule: parseInt(schedule_id),
		                            planninghorse_order: sch.order,
		                            planninghorse_valide: 1
		                        })
				            })
				        } else {
				        	await this.$storage.db.t('planning_schedule')
				            .then(table => {
				                return table.where({schedule_id: sch.id})
		                			.modify({
			                            schedule_start: sch.start,
			                            schedule_end: sch.end,
		                            	schedule_order: sch.order
		                			})
							})

							await this.$storage.db.t('planning_horse')
				            .then(table => {
				                return table.where({planninghorse_schedule: sch.id})
		                			.modify({ 
			                            planninghorse_stallion: sch.stallion.planninghorse_stallion.horse_id,
		                           	 	planninghorse_order: sch.order
			                        })
				            })
				        }
		            })

                	return planning_id
            //     }
            // )
    	},

    	async getPlanningInfo(season_id, lieu_id, type) {
    		return await this.$storage.db.t('planning')
	            .then(table => {
	                return table.where({
	                	planning_season: parseInt(season_id), 
	                	planning_type: type,
	                	planning_lieu: parseInt(lieu_id)
	                }).first()
				})
				.then(plan => {
					return PlanningTransformer.process(plan)
				})
		},

		async planningHasSchedule(planning_id) {
			const schedule = await this.$storage.db.t('planning_schedule')
	            .then(table => {
	                return table.where({schedule_planning: parseInt(planning_id)})
				})
				.then(plan => {
					return PlanningScheduleTransformer.process(plan)
				})
			return schedule.length > 0
		},

    	async getPlanning(season_id, type, date, lieu_id) {
    		const planning = await this.$storage.db.t('planning')
	            .then(table => {
	                return table.where({planning_season: parseInt(season_id), planning_type: type, planning_lieu: parseInt(lieu_id)}).first()
				})
				.then(plan => {
					return PlanningTransformer.process(plan)
				})

			if(!planning) return {}

			let schedules = []

			await Common.asyncForEach(
				planning.planning_schedule,
				async (schedule) => {
					schedule.schedule_info = await this.$storage.db.t('planning_schedule_horse')
		            .then(table => {
		                return table.where({schedulehorse_schedule: parseInt(schedule.schedule_id)})
						.filter(sch => {
							if(!date) return false
							date.setHours(0,0,0,0)
							return new Date(sch.schedulehorse_date).toDateInputValue() == new Date(date).toDateInputValue()
						})
					})
					.then(col => {
						return col.transform(new PlanningScheduleHorseTransformer())
					})
					.then(result => {
						let schedule_info = {}

						result.forEach(res => {
							if(!Object.prototype.hasOwnProperty.call(schedule_info, res.schedulehorse_stallion.horse_id)) {
								schedule_info[res.schedulehorse_stallion.horse_id] = [res]
							} else {
								schedule_info[res.schedulehorse_stallion.horse_id].push(res)
							}
						})

						return schedule_info
					})

					schedules.push(schedule)
			})

			planning.planning_horse = _orderBy(planning.planning_horse, 'planninghorse_schedule.schedule_order')
			planning.planning_schedule = schedules
			planning.date = date

			return planning
    	},

		getPlanningInfoByMare: async function(mare_id) {
        	const url = this.constructRoute(Constants.PLANNING_MONTE_BY_MARE, { mare_id }) + '?licence_key=' + Constants.USER_LICENCE_KEY

			return this.$request.request_get_api("PlanningMonteMixin::getPlanningInfoByMare", url)
			.then(res => {
				return res.retour
			})
			.catch(error => {
				console.error("PlanningMonteMixin::getPlanningInfoByMare => ERROR", error)
				return []
			})
        },

    	async getSchedules(planning_id) {
    		return await this.$storage.db.t('planning_schedule')
	            .then(table => {
	                return table.where({schedule_planning: parseInt(planning_id)})
				})
				.then(col => {
					return col.transform(new PlanningScheduleTransformer())
				})
    	},

    	async saveScheduleHorse(type, stallion_id, schedule_id, mare_id, contact_id, date, schedulehorse_id, commentaire, tiers_id) {
    		if(schedulehorse_id){
	    		await this.$storage.db.t('planning_schedule_horse')
				    .then(table => {
				    	return table.where({schedulehorse_id: parseInt(schedulehorse_id)}).invalid()
				    })
    		}

    		if(type == "main") {
	    		await this.$storage.db.t('planning_schedule_horse')
				    .then(table => {
				    	return table.where({schedulehorse_stallion: parseInt(stallion_id), schedulehorse_schedule: parseInt(schedule_id), schedulehorse_date: date}).invalid()
				    })
    		}

    		if(mare_id && contact_id) {
    			const has_contract = await this.loadHorseContact(mare_id, contact_id)
    			if(has_contract.length == 0) {
		    		await this.addHorseContact(mare_id, contact_id)
    			}
    		}

    		return await this.$storage.db.t('planning_schedule_horse')
	            .then(table => {
	                return table.add({ 
                        schedulehorse_id: Common.getNegativeId(),
                        schedulehorse_stallion: parseInt(stallion_id),
                        schedulehorse_schedule: parseInt(schedule_id),
                        schedulehorse_mare: mare_id ? parseInt(mare_id) : null,
                        schedulehorse_contact: contact_id ? parseInt(contact_id) : null,
                        schedulehorse_tiers: tiers_id ? parseInt(tiers_id) : null,
                        schedulehorse_date: date,
                        schedulehorse_commentaire: commentaire,
                        schedulehorse_valide: 1
                    })
	            })
    	},

    	async getStallionPlanning(planning_id) {
    		const stallion = await this.$storage.db.t('planning_horse')
	            .then(table => {
	                return table.where({planninghorse_planning: parseInt(planning_id)}).toArray()
	            })
	        return stallion.map(horse => horse.planninghorse_stallion)
    	},

    	async getSchedulesByStallionMain(horse_id, date) {
			const taken = await this.$storage.db.t('planning_schedule_horse')
	            .then(table => {
	                return table.where({schedulehorse_stallion: parseInt(horse_id)})
	                	.filter(sch => new Date(sch.schedulehorse_date).toDateInputValue() == new Date(date).toDateInputValue())
	                	.toArray()
	            })
	        return taken
    	},

    	async getSchedulesByStallionMainOnline(stallion_id, date) {
			const url = this.constructRoute(Constants.PLANNING_SCHEDULE_STALLION_DATE, {stallion_id, date: date.toDateInputValue()}) + "?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_get_api("PlanningMonteMixin::getSchedulesByStallionMainOnline", url)
				.catch(e => {
					console.error("PlanningMonteMixin::getSchedulesByStallionMainOnline => ", e)
					return null
				})
				.then(res => res.retour)
    	},

    	async getSchedulesByStallionIA(horse_id, planning_id) {
			return this.$storage.db.t('planning_horse')
	            .then(table => {
	                return table.where({planninghorse_planning: parseInt(planning_id), planninghorse_stallion: parseInt(horse_id)}).toArray()
	            })
    	},

    	async deleteScheduleHorse(schedulehorse_id) {
    		await this.$storage.db.t('planning_schedule_horse')
			    .then(table => {
			    	return table.where({schedulehorse_id: parseInt(schedulehorse_id)}).invalid()
			    })
    	},

    	async deleteScheduleOnline(type, schedulehorse_id) {
    		let url = this.constructRoute(Constants.PLANNING_MONTE_EDIT_SCHEDULE, {type, schedulehorse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			const response = await this.$request.request_delete_api("PlanningMonteMixin::deleteScheduleOnline", url)
			.catch(e => {
				console.log(e);
				console.error("PlanningMonteMixin::deleteScheduleOnline", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response
    	},


    	async getResidencePlanningDefault(season_id, type) {
	        return await this.$storage.db.t('planning')
	            .then(table => {
	                return table
	                .where({
	                	planning_season: parseInt(season_id), 
	                	planning_type: type,
	                })
	                .filter(planning => planning.planning_default)
	                .first()
				})
				.then(plan => {
					return PlanningTransformer.process(plan, 'light')
				})
    	},

		async getPlanningOnline(season_id, type, date_planning, lieu_id) {
			const date = date_planning.toDateInputValue()
			const url = this.constructRoute(Constants.PLANNING_MONTE_BY_SEASON_AND_LIEU, {season_id, type, date, lieu_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
			return this.$request.request_get_api("PlanningMonteMixin::getPlanningOnline", url)
				.catch(e => {
					console.error("PlanningMonteMixin::getPlanningOnline => ", e)
					return null
				})
				.then(res => res.retour)
		},

    	async saveScheduleHorseOnline(type, stallion_id, schedule_id, mare_id, contact_id, date, schedulehorse_id, commentaire, tiers_id) {
    		let url = this.constructRoute(Constants.PLANNING_MONTE_ADD_SCHEDULE, {type}) + "?licence_key="+Constants.USER_LICENCE_KEY
    		if(schedulehorse_id) {
    			url = this.constructRoute(Constants.PLANNING_MONTE_EDIT_SCHEDULE, {type, schedulehorse_id}) + "?licence_key="+Constants.USER_LICENCE_KEY
    		}

    		const params = {stallion_id, mare_id, contact_id, date, schedule_id, commentaire, tiers_id}

			const response = await this.$request.request_post_api("PlanningMonteMixin::saveScheduleHorseOnline", url, params, false)
			.catch(e => {
				console.log(e);
				console.error("PlanningMonteMixin::saveScheduleHorseOnline", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response.retour
    	},

    	async savePlanningComment(comment, lieu, date) {
    		let url = Constants.PLANNING_MONTE_COMMENTAIRE+"?licence_key="+Constants.USER_LICENCE_KEY
			const params = {'commentaire':comment, 'lieu':lieu, 'date':date}
			const response = await this.$request.request_post_api("PlanningMonteMixin::savePlanningComment", url, params, false)
			.catch(e => {
				console.log(e);
				console.error("PlanningMonteMixin::savePlanningComment", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response.retour
    	},


    	async getPlanningComment(lieu, date) {
    		let url = Constants.PLANNING_MONTE_COMMENTAIRE+"?licence_key="+Constants.USER_LICENCE_KEY+"&date="+date+"&lieu="+lieu
			const response = await this.$request.request_get_api("PlanningMonteMixin::getPlanningComment", url)
			.catch(e => {
				console.log(e);
				console.error("PlanningMonteMixin::getPlanningComment", e)
				this.failureToast("toast.info_save_failed")
				return null
			})

			return response.retour
    	},

    	 async printPlanning(season_id, type, lieu_id, date_start, date_end, only_active = false) {
            const url = this.constructRoute(Constants.PLANNING_MONTE_PRINT, {season_id, type, lieu_id, date_start, date_end}) + "?licence_key="+Constants.USER_LICENCE_KEY+"&only_active="+only_active
            const result = await this.$request.request_get_api("ResultatAnalyse::printPlanning", url)
            .catch(error => {
                console.error("ResultatAnalyse::printPlanning => ERROR", error)
                return null
            })
            Common.base64ToPdf(result, "planning_"+type+".pdf")
            return true
        },

    	 async printPlanningDay(season_id, type, lieu_id, date, only_active = false) {
            const url = this.constructRoute(Constants.PLANNING_MONTE_PRINT_DAY, {season_id, type, lieu_id, date}) + "?licence_key="+Constants.USER_LICENCE_KEY+"&only_active="+only_active
            const result = await this.$request.request_get_api("ResultatAnalyse::printPlanning", url)
            .catch(error => {
                console.error("ResultatAnalyse::printPlanning => ERROR", error)
                return null
            })
            Common.base64ToPdf(result, "planning_"+type+".pdf")
            return true
        },

		async printDayFeuilleSaillie(season_id, type, lieu_id, date, model_id, creneaux_ids) {
			const params = {model_id, creneaux_ids}

            const url = this.constructRoute(Constants.PLANNING_MONTE_PRINT_FEUILLE, {season_id, type, lieu_id, date}) + "?licence_key="+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_post_api("PlanningMonteMixin::printDayFeuilleSaillie", url, params, false)
            .catch(error => {
                console.error("PlanningMonteMixin::printDayFeuilleSaillie => ERROR", error)
                return null
            })
            Common.base64ToPdf(result, "feuille_saillie_"+type+".pdf")
            return true
        },
	}
}

export default PlanningMonte
